<template>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-card
          class="mx-auto"
          outlined
          style="width: 500px;"
        >
          <v-card-title
          class="blue darken-3 white--text"
          >
            Login
          </v-card-title>
          <v-card-text
            class="pt-5"
          >
            <v-text-field
              outlined
              dense
              prepend-icon="person"
              v-model="c.username"
              name="Email"
              label="Email"
              type="text"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              prepend-icon="lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="Password"
              label="Password"
              v-model="c.password"
              class="input-group--focused"
              @click:append="show = !show"
              @keypress.enter="login"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              x-small
              @click="forgotPassword()"
            >
              Forgot Password?
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn color="info" class="white--text" @click="register">Register</v-btn> -->
            <v-btn color="primary" class="white--text" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>        

      </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {    
  },
  props: [
  ],
  data: () => ({
    c: {
      username: null,
      password: null
    },
    trial_user: {},
    show: false,
    trialDialog: false,
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
  }),
  mounted () {
    window.location.href = "https://new.profitabletradesmen.com/"
  },
  created () {
    if(localStorage.getItem('token') !== null){
      let ld = JSON.parse(localStorage.getItem('udata'))
      if(ld.role_id === 1){
        this.$router.push({name: 'landing'})
      }else if(ld.role_id === 2){
        this.$router.push({name: 'client'})
      }else if(ld.role_id === 4){
        this.$router.push({name: 'internal'})
      }
    }
  },
  computed: {
    ...mapGetters({
      mSnackbar:    'auth/mSnackbar',
      getCurrentRoute:  'auth/getCurrentRoute',
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    async login(){
      await this.$axios.get('/csrf-cookie')
      .then(response => {
        if(response){
          this.$axios.post('login', this.c)
          .then(({data}) => {
            let snackbar = {
              show: true,
              message: data.message,
              button: false
            };
            if(data.response){
              // this.$axios.post(`clogin/${data.udata.id}`);
              sessionStorage.setItem('token', data.data);
              sessionStorage.setItem('udata', JSON.stringify(data.udata));
              localStorage.setItem('token', data.data)
              localStorage.setItem('udata', JSON.stringify(data.udata))
              this.$store.dispatch('auth/setAuthToken', localStorage.getItem('token'));
              this.$store.dispatch('auth/setMessage', snackbar);
              this.$store.dispatch('auth/setToLoad', true)
              this.$store.dispatch('auth/setIsLogged', true)
              this.$store.dispatch('auth/setUserData', JSON.stringify(data.udata))
              this.$store.dispatch('auth/setSelectedUser', JSON.stringify(data.udata));

              let payload = {
                last_check_out: localStorage.getItem('last_check_out'),
                login: true
              }
              this.$axios.post('clients/check_in', payload)
              
              if(this.getCurrentRoute) {
                this.$router.push({ name: 'clientcurrentlesson', query: { query: this.getCurrentRoute } })
              } else {
                if(data.udata.role_id == 1){
                  this.$router.push({name: 'landing'})
                }
                
                if(data.udata.role_id == 2 || data.udata.role_id == 5){
                  this.$router.push({name: 'clientv2'})
                }
              } 
            } else {
              this.$store.dispatch('auth/setMessage', snackbar);
            }
          })
        }
      })
    },
    register(){
      console.log(this.c)
    },
    forgotPassword() {
      this.$router.push({ name: 'forgot-password' }).catch(err => err);
    },
    trialTimeout() {
      this.trialDialog = true;
    },
    async submitTrialForm() {
      const temp = this.$refs.form.validate();

      const topass = {
        ...this.trial_user,
        course_id: 3,
        role_id: 5,
        membership_type: 'Trial'
      }

      if(temp) {
        await this.$axios.post('/trialRegistration', topass)
          .then(({data}) => {
            this.trialDialog = false;
            if(data.response) {
              this.showSnackBar(data.message);
              this.$axios.post('/a_client', topass)
                .then(({data}) => {
                  if(data.response) {
                    console.log(data);
                  }
                })
            } else {
              this.showSnackBar('auth/setMessage', 'Error! please contact the admin / developer to issue this bug.');
            }
          });
      } else {
        this.showSnackBar('Please double check your input.');
      }
    }
  },
  watch: {
  }
}
</script>

<style scoped>
  .forgot {
    text-decoration: none;
  }
</style>